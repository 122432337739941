import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useLayout } from '~hooks/useLayout';
import { useSeo } from '~hooks/useSeo';

import '../style.scss';

const LoggedOut = () => {
  const intl = useIntl();

  useLayout({ displayCities: false });
  useSeo(
    intl.formatMessage({
      id: 'containers.authentication.logged-out.seo.title',
    })
  );

  return (
    <section className="c-authentication l-container">
      <div className="c-authentication__content">
        <h1 className="l-headline--secondary">
          {intl.formatMessage({
            id: 'containers.authentication.logged-out.headline',
            defaultMessage: 'Thank you for your visit',
          })}
        </h1>

        <div className="c-authentication__intro">
          {intl.formatMessage({
            id: 'containers.authentication.logged-out.content',
            defaultMessage: 'You have successfully logged out.',
          })}
        </div>

        <div className="c-form__buttons">
          <Link to="/" className="c-btn">
            {intl.formatMessage({
              id: 'containers.authentication.logged-out.button',
              defaultMessage: 'Go to homepage',
            })}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default LoggedOut;
